const constants = {
  // Map Info
  '_mapTitle': 'Kraftorte in Berlin',
  // Map Settings
  '_markercluster': 'on',
  '_initZoom': '',
  '_initLat': '',
  '_initLon': '',
  // Map Controls
  '_mapSearch': 'topright',
  '_mapRouting': 'car',
  '_mapSearchCircleRadius': '20 miles',
  '_mapSearchZoom': 12,
  '_mapZoom': 'topright',
  '_mapMyLocation': 'topright',
  '_menuOpen': ['Kraftorte'],
  '_categorySelected': ['Kraftorte'],
  '_groupSelected': [],
  '_popupDisabled': [],
  '_heatmap': [],
  // Table
  '_tableHeader': false,
  '_tableCategories': ['Kraftorte'],
  '_tableColumns': ['Gruppe', 'Image', 'Name', 'Beschreibung', 'Adresse', 'Boviseinheiten', 'Online'],
};

module.exports = constants;

